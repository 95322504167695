'use client'
import React, { useContext } from 'react'
import { useRouter } from 'next/navigation'

import { ViewportContext } from '@/context/ViewportContext'
import { EventsContext } from '@/context/EventsContext'
import { SearchLocationContext } from '@/context/SearchLocationContext'

import Header from '@/components/Results/components/Header/Header'
import List from '@/components/Results/components/List/List'
import Event from '@/components/Results/components/Cards/Event/Event'
import SectionBanner from '../SectionBanner/SectionBanner'
import Button from '@/components/ui/Button/Button'

import useViewport from '@/hooks/useViewport'
import hypenString from '@helpers/formatters/hyphenLowercaseString'

import Icon from '@/assets/icons/arrows/arrow-submit.svg'

// common classes
import classes from '@/components/Results/Results.module.scss'

export default function Events() {
  const { push } = useRouter()
  const { location: locationContext } = useContext(SearchLocationContext)
  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })

  const { events } = useContext(EventsContext)

  const titleText = 'Local events near'

  const link = {
    title:
      locationContext?.name && locationContext?.state_code
        ? `Explore Events near ${locationContext?.name}, ${locationContext?.state_code}`
        : 'Explore all Events',
    href:
      locationContext && locationContext.state_code && locationContext.name
        ? `/${hypenString(locationContext.state_code)}/${hypenString(locationContext.name)}/events`
        : '#',
  }

  return (
    <div className={classes.root}>
      <Header
        title={titleText}
        total={events?.total || 0}
        location={{
          value: locationContext,
        }}
      />
      <div className={`${classes.body} ${classes.banner} ${classes.banner_left}`}>
        {viewport !== 'mobile' && <SectionBanner id={2} link={link} />}
        {events?.data && events?.data.length > 0 && (
          <List columns={viewport === 'desktop' ? 3 : undefined}>
            {events.data.map((event, i) => {
              return <Event key={i} event={event} />
            })}
          </List>
        )}
      </div>
      {viewport === 'mobile' && (
        <Button
          text={link.title}
          Icon={Icon}
          styles={{ marginTop: '14px', maxWidth: '358px' }}
          onClick={() => push(link.href)}
        />
      )}
    </div>
  )
}
