'use client'
import React, { useContext, useState } from 'react'

import classes from './FeaturesTabs.module.scss'

import { TABS } from './constants'

import Content from './components/Content/Content'
import Media from './components/Media/Media'
import Tab from './components/Tab/Tab'
import { ViewportContext } from '@/context/ViewportContext'
import useViewport from '@/hooks/useViewport'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

export default function FeaturesTabs() {
  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })
  const [tab, setTab] = useState<number>(1)

  const handleTabSwitch = (key) => {
    setTab(Number(key))

    const { event_category, event_label } = TABS[key]

    trackGAEvent({
      action: 'click',
      category: event_category,
      label: event_label,
      data: `page_url: ${window.location.href}`,
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Content id={tab} />
        <div className={classes.tabs}>
          {Object.keys(TABS).map((key, i) => {
            const id = Number(key)
            return (
              <Tab
                key={i}
                onClick={() => handleTabSwitch(id)}
                name={TABS[key].name}
                index={i}
                viewport={viewport}
                active={tab === Number(key)}
              />
            )
          })}
        </div>
      </div>
      <Media id={tab} viewport={viewport} />
    </div>
  )
}
