'use client'
import React, { useContext } from 'react'
import { useRouter } from 'next/navigation'

import { ActivitiesContext } from '@/context/ActivitiesContext'
import { ViewportContext } from '@/context/ViewportContext'
import { SearchLocationContext } from '@/context/SearchLocationContext'

import Header from '@/components/Results/components/Header/Header'
import List from '@/components/Results/components/List/List'
import Activity from '@/components/Results/components/Cards/Activity/Activity'

import SectionBanner from '../SectionBanner/SectionBanner'

import useViewport from '@/hooks/useViewport'
import hypenString from '@helpers/formatters/hyphenLowercaseString'
import Button from '@/components/ui/Button/Button'
import Icon from '@/assets/icons/arrows/arrow-submit.svg'

// common classes
import classes from '@/components/Results/Results.module.scss'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

export default function Activities() {
  const { push } = useRouter()
  const { isMobile } = useContext(ViewportContext)
  const { location: locationContext } = useContext(SearchLocationContext)
  const viewport = useViewport({ isMobile })

  const { activities } = useContext(ActivitiesContext)
  const titleText = 'Find activities near'

  const link = {
    title:
      locationContext?.name && locationContext?.state_code
        ? `See all Activies near ${locationContext?.name}, ${locationContext?.state_code}`
        : 'See all Activies',
    href:
      locationContext && locationContext.state_code && locationContext.name
        ? `/${hypenString(locationContext.state_code)}/${hypenString(locationContext.name)}/activities`
        : '/',
  }

  const handleMapView = locationContext?.coordinate
    ? () => {
        const { lat, lon } = locationContext.coordinate

        trackGAEvent({
          action: 'click',
          category: 'Activities Section',
          label: `Map View`,
          data: `page_url: ${window.location.href}`,
        })

        window.open(`/map?location=${encodeURIComponent(`${lat},${lon}`)}`, '_ blank')
      }
    : undefined

  return (
    <div className={classes.root}>
      <Header
        title={titleText}
        total={activities?.total || 0}
        location={{
          value: locationContext,
        }}
        onShowMapClick={handleMapView}
      />
      <div className={`${classes.body} ${classes.banner} ${classes.banner_right}`}>
        {activities?.data && activities?.data.length > 0 && (
          <List columns={viewport === 'desktop' ? 3 : undefined}>
            {activities?.promoted && <Activity activity={activities?.promoted} wide={true} />}
            {activities.data.map((activity, i) => {
              return <Activity key={i} activity={activity} />
            })}
          </List>
        )}
        {viewport !== 'mobile' && <SectionBanner id={1} link={link} />}
      </div>
      {viewport === 'mobile' && (
        <Button
          text={link.title}
          Icon={Icon}
          styles={{ marginTop: '14px', maxWidth: '358px' }}
          onClick={() => push(link.href)}
        />
      )}
    </div>
  )
}
