import React from 'react'
import Image from 'next/image'

import Button from '@/components/ui/Button/Button'

import Icon from '@/assets/icons/arrows/arrow-submit.svg'

import classes from './SectionBanner.module.scss'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

type TSectionBanner = {
  id: number
  link: {
    title: string
    href: string
  }
}

const VARIANTS = {
  1: {
    title: 'Explore your neighborhood',
    text: 'Are you on the lookout for the perfect activities to engage your little ones right in your neighborhood? Look no further! We understand the importance of finding enriching experiences that not only entertain but also promote growth and learning. From best summer camps to  after-school programs our mission is to make your search for the ideal activities as effortless as possible.',
    image: {
      src: '/redesign/assets/images/section-banner/1.webp',
      alt: 'Kids drawing',
      width: 411,
      height: 258,
    },
  },
  2: {
    title: 'Ready to have fun?',
    text: "Seeking the ultimate local events for your kids? Look no further! We're dedicated to curating a selection of the finest neighborhood happenings tailored just for them and you. Explore enriching experiences that foster growth and fun, all conveniently located right in your own backyard.",
    image: {
      src: '/redesign/assets/images/section-banner/2.webp',
      alt: 'Kid playing with baloons',
    },
  },
  3: {
    title: 'Knowledge is power.',
    text: "Dive into our treasure trove of articles catering to every aspect of your child's world! Explore engaging pieces on kids' experiences, culinary adventures, educational insights, and much more. Unlock a wealth of knowledge and inspiration to enrich your parenting journey. Let's embark on this exciting exploration together!",
    image: {
      src: '/redesign/assets/images/section-banner/3.webp',
      alt: 'Woman in glasses reads book and smile',
    },
  },
}

export default function SectionBanner({ id, link }: TSectionBanner) {
  const data = VARIANTS[id]
  const event_category = 'Section Banner'

  const handleClick = () => {
    window.open(link.href)

    trackGAEvent({
      action: 'click',
      category: event_category,
      label: link.title,
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div
          className={classes.image}
          style={{ maxWidth: data.image.width, maxHeight: data.image.height }}
        >
          <Image src={data.image.src} alt={data.image.alt} fill={true} />
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.content}>
          <b className={classes.title}>{data.title}</b>
          <p className={classes.text}>{data.text}</p>
        </div>
        <div className={classes.button}>
          <Button text={link.title} Icon={Icon} onClick={handleClick} styles={{ width: '100%' }} />
        </div>
      </div>
    </div>
  )
}
