import React from 'react'

import Image from 'next/image'

import { Viewport } from '../../../../../../types'

import { TABS } from '../../constants'

import classes from './Media.module.scss'

type TMedia = {
  id: number
  viewport: Viewport
}

export default function Media({ id, viewport }: TMedia) {
  const media = TABS[id].media

  if (!media) {
    return null
  }

  let thumbnailClasses = classes.thumbnail

  if (media.image) {
    let thumbnail = {
      src: media.image.mob.src,
      width: 358,
      height: 319,
      fill: true,
      // fill: false,
    }

    if (viewport === 'tablet') {
      thumbnail = {
        src: media.image.tablet.src,
        width: 270,
        height: 385,
        fill: false,
      }
    }

    if (viewport === 'desktop') {
      thumbnail = {
        src: media.image.desktop.src,
        width: 609,
        height: 494,
        fill: false,
      }
    }

    if (media.video) {
      thumbnailClasses += ` ${classes.thumbnail_video}`
    }

    return (
      <div className={classes.root} style={{ maxHeight: thumbnail?.height || 'unset' }}>
        {media.image && (
          <div className={thumbnailClasses}>
            <Image
              src={thumbnail.src}
              alt={''}
              width={!thumbnail.fill ? thumbnail.width : undefined}
              height={!thumbnail.fill ? thumbnail.height : undefined}
              fill={thumbnail.fill}
              priority={true}
            />
          </div>
        )}
      </div>
    )
  }

  return null
}
