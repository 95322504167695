import React from 'react'

import classes from './Card.module.scss'

type TCard = {
  title: string
  text: string
}

export default function Card({ title, text }: TCard) {
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.text}>{text}</p>
    </div>
  )
}
