'use client'

import React from 'react'

import { ActivitiesContext, TActivitiesContext } from '@/context/ActivitiesContext'
import {
  SeasonActivitiesContext,
  TSeasonActivitiesContext,
} from '@/context/SeasonActivitiesContext'
import { ExperiencesContext, TExperiencesContext } from '@/context/ExperiencesContext'
import { ArticlesContext, TArticlesContext } from '@/context/ArticlesContext'
import { EventsContext, TEventsContext } from '@/context/EventsContext'
import { SearchLocationContext, TSearchLocationContext } from '@/context/SearchLocationContext'

type TProviders = {
  children: React.ReactNode
  activities: TActivitiesContext
  seasonActivities: TSeasonActivitiesContext
  articles: TArticlesContext
  experiences: TExperiencesContext
  events: TEventsContext
  location: TSearchLocationContext
}

export function HomePageProviders({
  children,
  activities,
  seasonActivities,
  articles,
  experiences,
  events,
  location,
}: TProviders) {
  return (
    <ActivitiesContext.Provider value={activities}>
      <SeasonActivitiesContext.Provider value={seasonActivities}>
        <EventsContext.Provider value={events}>
          <ExperiencesContext.Provider value={experiences}>
            <ArticlesContext.Provider value={articles}>
              <SearchLocationContext.Provider value={location}>
                {children}
              </SearchLocationContext.Provider>
            </ArticlesContext.Provider>
          </ExperiencesContext.Provider>
        </EventsContext.Provider>
      </SeasonActivitiesContext.Provider>
    </ActivitiesContext.Provider>
  )
}
