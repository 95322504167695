'use client'
import React, { useContext } from 'react'

import { ViewportContext } from '@/context/ViewportContext'
import useViewport from '@/hooks/useViewport'

import Header from '@/components/Results/components/Header/Header'
import List from '@/components/Results/components/List/List'

import Article from '@/components/Results/components/Cards/Article/Article'

import { ArticlesContext } from '@/context/ArticlesContext'
import SectionBanner from '../SectionBanner/SectionBanner'
import classes from '@/components/Results/Results.module.scss'
import Button from '@/components/ui/Button/Button'
import Icon from '@/assets/icons/arrows/arrow-submit.svg'
import { useRouter } from 'next/navigation'
import hypenString from '@helpers/formatters/hyphenLowercaseString'
import { SearchLocationContext } from '@/context/SearchLocationContext'

export default function Articles() {
  const { push } = useRouter()
  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })
  const { location: locationContext } = useContext(SearchLocationContext)

  const { articles } = useContext(ArticlesContext)

  const titleText = 'Articles'

  const link = {
    title: 'See all Articles',
    href:
      locationContext && locationContext.state_code && locationContext.name
        ? `/${hypenString(locationContext.state_code)}/${hypenString(locationContext.name)}/articles`
        : '/articles',
  }

  return (
    <div className={classes.root}>
      <Header title={titleText} total={articles?.total || 0} />
      <div className={`${classes.body} ${classes.banner} ${classes.banner_right}`}>
        {articles?.data && articles?.data.length > 0 && (
          <List columns={viewport === 'desktop' ? 2 : undefined}>
            {articles.data.map((article, i) => {
              return <Article data={article} key={i} />
            })}
          </List>
        )}
        {viewport !== 'mobile' && <SectionBanner id={3} link={link} />}
      </div>
      {viewport === 'mobile' && (
        <Button
          text={link.title}
          Icon={Icon}
          styles={{ marginTop: '14px', maxWidth: '358px' }}
          onClick={() => push(link.href)}
        />
      )}
    </div>
  )
}
