// import Image from '@/assets/images/feature-tabs/smile-kids.webp'
import Image from '@/assets/images/feature-tabs/children-playing-grass.webp'
import ImageMob from '@/assets/images/feature-tabs/children-playing-grass-mob.webp'

const event_category = 'Features Tab (Blue)'

const image = {
  mob: ImageMob,
  tablet: Image,
  desktop: Image,
}

export const TABS = {
  1: {
    name: 'For parents',
    event_category,
    event_label: 'For Parents',
    position: 'left',
    content: {
      title: 'Find local activities',
      text: 'Find local activities for your kids: Explore nearby events, classes, camps and attractions to keep your little ones entertained and engaged all year around.',
      search: true,
    },
    media: {
      image,
      // video: {
      //   link: undefined,
      // },
    },
  },
  2: {
    name: 'For businesses',
    position: 'center',
    event_category,
    event_label: 'For businesses',
    content: {
      title: 'Find local activities',
      text: 'Streamline your after-school and summer program: Manage classes, registrations, payments, forms, and communication effortlessly with our comprehensive solution designed for service providers.',
      link: 'https://beakid.com/for-business',
      buttonText: 'Explore business options',
    },
    media: {
      image,
      // video: {
      //   link: undefined,
      // },
    },
  },
  3: {
    name: 'Marketers',
    event_category,
    event_label: 'Marketers',
    position: 'right',
    content: {
      title: 'Find local activities',
      text: 'Expand your customer base and gain new audience. With millions of parents on our platform, service providers  as well as local businesses can effortlessly attract new customers and.',
      link: 'https://beakid.com/marketing',
      buttonText: 'Explore marketers options',
    },
    media: {
      image,
      // video: {
      //   link: undefined,
      // },
    },
  },
}
