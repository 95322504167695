import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/local/www/gis.beakid.com-nextjs/src/apps/HomePageApp/components/Activities/Activities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/local/www/gis.beakid.com-nextjs/src/apps/HomePageApp/components/Articles/Articles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/local/www/gis.beakid.com-nextjs/src/apps/HomePageApp/components/Events/Events.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/local/www/gis.beakid.com-nextjs/src/apps/HomePageApp/components/Experiences/Experiences.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/local/www/gis.beakid.com-nextjs/src/apps/HomePageApp/components/FeaturesTabs/FeaturesTabs.tsx");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/apps/HomePageApp/components/Statistics/Statistics.tsx");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/apps/HomePageApp/components/SubscribeNewsletter/SubscribeNewsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/local/www/gis.beakid.com-nextjs/src/components/FixedHeader/FixedHeader.tsx");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Info/Info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/local/www/gis.beakid.com-nextjs/src/components/NavActions/NavActions.tsx");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Sections/components/Heading/Heading.module.scss");
;
import(/* webpackMode: "eager" */ "/local/www/gis.beakid.com-nextjs/src/components/Sections/components/Section/Section.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["HomePageProviders"] */ "/local/www/gis.beakid.com-nextjs/src/providers/HomePageProvider.tsx");
