import React from 'react'

import MobLeft from '@/assets/icons/feature-tabs/mob/left.svg'
import MobCenter from '@/assets/icons/feature-tabs/mob/center.svg'
import MobRight from '@/assets/icons/feature-tabs/mob/right.svg'

import TabletLeft from '@/assets/icons/feature-tabs/tablet/left.svg'
import TabletCenter from '@/assets/icons/feature-tabs/tablet/center.svg'
import TabletRight from '@/assets/icons/feature-tabs/tablet/right.svg'

import DesktopLeft from '@/assets/icons/feature-tabs/desktop/left.svg'
import DesktopCenter from '@/assets/icons/feature-tabs/desktop/center.svg'
import DesktopRight from '@/assets/icons/feature-tabs/desktop/right.svg'

import classes from './Tab.module.scss'
import { Viewport } from '../../../../../../types'

type TTab = {
  onClick: () => void
  name: string
  viewport: Viewport
  index: number
  active: boolean
}

const BACKGROUNDS = {
  left: {
    mobile: MobLeft,
    tablet: TabletLeft,
    desktop: DesktopLeft,
  },
  center: {
    mobile: MobCenter,
    tablet: TabletCenter,
    desktop: DesktopCenter,
  },
  right: {
    mobile: MobRight,
    tablet: TabletRight,
    desktop: DesktopRight,
  },
}

export default function Tab({ name, index, onClick, viewport, active }: TTab) {
  const positions = ['left', 'center', 'right']
  const position = positions[index] || 'left'

  let rootClasses = `${classes.root} ${classes[`root_${position}`]}`

  if (active) {
    rootClasses += ` ${classes.root_active}`
  }

  const BackgroundSvg = BACKGROUNDS[position][viewport]

  return (
    <div className={rootClasses} onClick={onClick}>
      <div className={classes.background}>
        <BackgroundSvg />
      </div>
      {position === 'center' && (
        <div className={`${classes.background} ${classes.background_right}`}>
          <BackgroundSvg />
        </div>
      )}
      <span className={classes.name}>{name}</span>
    </div>
  )
}
