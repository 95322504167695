'use client'
import React, { useContext } from 'react'

import classes from './Experiences.module.scss'
import Icon from '@/assets/icons/arrows/arrow-submit.svg'
import Button from '@/components/ui/Button/Button'
import { ExperiencesContext } from '@/context/ExperiencesContext'
import Experience from '@/components/Results/components/Cards/Experience/Experience'
import { ViewportContext } from '@/context/ViewportContext'
import useViewport from '@/hooks/useViewport'
import { EXPERIENCES_LIMIT } from '../../../../app/constants'
import hypenString from '@helpers/formatters/hyphenLowercaseString'
import { SearchLocationContext } from '@/context/SearchLocationContext'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

export default function Experiences() {
  const { location: locationContext } = useContext(SearchLocationContext)
  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })
  const { experiences } = useContext(ExperiencesContext)

  if (!experiences || experiences.length === 0) {
    return null
  }

  const choiceUrlBase =
    locationContext?.state_code && locationContext?.name
      ? `/${hypenString(locationContext?.state_code)}/${hypenString(locationContext?.name)}/choice`
      : ''

  const handleButtonCLick = () => {
    trackGAEvent({
      action: 'click',
      category: 'Choice Section',
      label: `Go To Choice Page`,
      data: `page_url: ${window.location.href}`,
    })
  }

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <h2 className={`section-title ${classes.title}`}>Search by activity type</h2>
        <p className={`section-description ${classes.text}`}>
          Explore activities tailored to your interests! Browse diverse list of categories including
          sports, arts, camps, educational and leisure.
        </p>
      </div>
      <div className={classes.results}>
        {experiences.map((experience, i) => {
          if (i + 1 > EXPERIENCES_LIMIT[viewport]) {
            return null
          }

          const hypenExpName = hypenString(experience.name)

          const href = choiceUrlBase ? `${choiceUrlBase}/${hypenExpName}` : ''

          return <Experience key={i} data={experience} href={href} />
        })}
      </div>
      <div className={classes.button}>
        <Button
          text={'Explore more categories'}
          Icon={Icon}
          styles={{ width: '100%' }}
          type="link"
          href={choiceUrlBase}
          onClick={handleButtonCLick}
        />
      </div>
    </div>
  )
}
