import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

import pluralize from 'pluralize'

import { Experience as ExperienceType } from '../../../../../../types'

import { toClassName } from '../../../../../../apps/CompanyDetailsPageApp/components/About/helpers/toClassName'

import classes from './Experience.module.scss'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

type TExperience = {
  data: ExperienceType
  disabled?: boolean
  href: string
}

export default function Experience({ data, href, disabled }: TExperience) {
  let rootClasses = classes.root

  if (disabled) {
    rootClasses += ` ${classes.root_disabled}`
  }

  const image = `/assets/company-details/experiences/${toClassName(data.name)}.svg`

  const handleClick = () => {
    trackGAEvent({
      action: 'click',
      category: 'Choice Section',
      label: `${data.name}`,
      data: `page_url: ${window.location.href}`,
    })
  }

  return (
    <Link
      href={href}
      replace={true}
      prefetch={false}
      passHref
      className={rootClasses}
      draggable="false"
      title={data.name}
      onClick={handleClick}
    >
      <Image src={image} width={50} height={50} alt={`${data.name} icon`} />
      <div className={classes.text}>
        <div className={'nowrap-adaptive-container'}>
          <b className={`nowrap-text ${classes.title}`}>{data.name}</b>
        </div>
        <p className={classes.count}>{pluralize('Activity', data.count, true)}</p>
      </div>
    </Link>
  )
}
