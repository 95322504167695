'use client'

import React from 'react'

import Button from '@/components/ui/Button/Button'
import Icon from '@/assets/icons/arrows/arrow-submit.svg'

import classes from './Info.module.scss'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'
import { useAuth } from '@/context/AuthContext'

type TInfo = {
  variant: 'one' | 'two' | 'three'
}

const info = {
  one: {
    title: 'Need Class Management Software?',
    description: 'Get the best system without braking a bank. Become BeAKid Partner.',
    button: {
      href: 'https://calendly.com/beakid/remote_product_presentation',
      title: 'Demo',
    },
  },
  two: {
    title: 'Need More Customers?',
    description:
      'Largest eCommerce platform at your fingertips.  List or promote your company or event.',
    button: {
      href: 'https://www.beakid.com/marketing',
      title: 'Learn more',
    },
  },
  three: {
    title: 'Tailored Adventures for Your Kids',
    description:
      'Explore a world of child specific experiences based on specified interests, ages, and location. Sign up today for FREE.',
    button: {
      href: '',
      title: 'Sign up',
    },
  },
}

export default function Info({ variant }: TInfo) {
  const { setIsOpen } = useAuth()
  const rootClasses = `${classes.root} ${classes[`root_${variant}`]}`

  const handleCLick = () => {
    trackGAEvent({
      action: 'click',
      category: 'Info Section',
      label: `${info[variant].title} Action Button`,
      data: `page_url: ${window.location.href}`,
    })

    if (variant === 'three') {
      setIsOpen(true)
    }
  }

  return (
    <div className={rootClasses}>
      <div className={classes.heading}>
        <h2 className={`section-title ${classes.title}`}>{info[variant].title}</h2>
        <p className={`section-description ${classes.description}`}>{info[variant].description}</p>
      </div>
      <div className={classes.button}>
        <Button
          text={info[variant].button.title}
          Icon={Icon}
          theme="contrast"
          onClick={handleCLick}
          href={info[variant].button.href}
          type="link"
        />
      </div>
    </div>
  )
}
