'use client'
import React, { useState, useRef } from 'react'

import { useMutation } from '@apollo/client'
import { SUBSCRIBE_NEWSLETTER_MUTATION } from '@components/Subscribe/mutations'

import ReCAPTCHA from 'react-google-recaptcha'

import Button from '@/components/ui/Button/Button'
import Input from '@/components/ui/Input/Input'

import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

import Icon from '@/assets/icons/arrows/arrow-submit.svg'

import classes from './SubscribeNewsletter.module.scss'

export default function SubscribeNewsletter() {
  const reCaptchaRef = useRef<any>(null)

  const [isSubscribed, setIsSubscribed] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [errors, setErrors] = useState({ email: '', name: '' })

  const [subscribeEmail] = useMutation(SUBSCRIBE_NEWSLETTER_MUTATION)

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    const nameValid = name.trim() !== ''

    setErrors({
      email: emailValid ? '' : 'Invalid email address',
      name: nameValid ? '' : 'Name cannot be empty',
    })

    if (emailValid && nameValid) {
      const token = await reCaptchaRef.current.executeAsync()

      if (token) {
        const eventData = JSON.stringify({ name, email })
        trackGAEvent({
          action: `click`,
          category: 'SubscribeNewsletter Section',
          label: `Subscribe`,
          data: eventData,
        })

        try {
          const { data } = await subscribeEmail({
            variables: {
              input: { name, email },
            },
          })

          if (data && data?.subscribeNewsletter) {
            const { id, email } = data.subscribeNewsletter
            const eventData = JSON.stringify({ subscribe_id: id, email })

            trackGAEvent({
              action: `event`,
              category: 'SubscribeNewsletter Section',
              label: `Successfully subscribed`,
              data: eventData,
            })

            setIsSubscribed(true)
          }
        } catch (e) {
          console.log('[SubscribeNewsletter] submit error', e)
        }
      }
    }
  }

  const handleChange = (e) => {
    if (e.target.id === 'newsletter-email') {
      setEmail(e.target.value)
    }

    if (e.target.id === 'newsletter-name') {
      setName(e.target.value)
    }
  }

  const disabled = name === '' || email === '' || isSubscribed

  return (
    <div className={classes.root}>
      <h2 className={`section-title ${classes.title}`}>
        Subscribe to our newsletter and stay updated.
      </h2>
      <form method="POST" onSubmit={handleSubmit} className={classes.form}>
        <Input
          id="newsletter-email"
          placeholder="Your email"
          value={email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <Input
          id="newsletter-name"
          placeholder="Your name"
          value={name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <Button
          type="submit"
          text={isSubscribed ? 'Subscribed' : 'Subscribe'}
          Icon={Icon}
          disabled={disabled}
          styles={{ minWidth: '156px' }}
        />
        {process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY && (
          <ReCAPTCHA
            ref={reCaptchaRef}
            size="invisible"
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          />
        )}
      </form>
    </div>
  )
}
