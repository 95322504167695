'use client'
import React, { useContext } from 'react'
import Image from 'next/image'

import { ViewportContext } from '@/context/ViewportContext'

import useViewport from '@/hooks/useViewport'

import classes from './Statistics.module.scss'
import Card from './component/Card/Card'

const CARDS = [
  {
    title: '10K',
    text: 'Customer leads sent to our  partners this year.',
  },
  {
    title: '5 Million',
    text: 'Parents found local fun activities for their kids',
  },
  {
    title: '20 Hours',
    text: 'Saved weekly for business operations',
  },
  {
    title: '40%',
    text: 'Parents spent more than 5 min. on the site',
  },
]

export default function Statistics() {
  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })

  let src = '/redesign/assets/images/statistics-map/statistics-map-300-209.webp'
  let width = 300
  let height = 209

  if (viewport !== 'mobile') {
    src = '/redesign/assets/images/statistics-map/statistics-map-682-453.webp'
    width = 682
    height = 453
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.heading}>
          <h2 className={`section-title ${classes.title}`}>Be local, act global.</h2>
          <p className={`section-description ${classes.text}`}>
            Our partners range from small mom and pop shops, to national brands, to parents who are
            looking to make the lives of children magical.
          </p>
        </div>
        <div className={classes.cards}>
          {CARDS.map((card, key) => {
            return (
              <div key={key} className={classes.item}>
                <Card {...card} />
              </div>
            )
          })}
        </div>
      </div>
      <div className={classes.image}>
        <Image alt={'BeAKid statistics map'} src={src} width={width} height={height} />
      </div>
    </div>
  )
}
