import React from 'react'

import IconArrow from '@/assets/icons/arrows/arrow-blue-white-bg-16x16.svg'
import Icon from '@/assets/icons/arrows/arrow-submit.svg'

import Button from '@/components/ui/Button/Button'
import Search from '@/components/Search/Search'

import classes from './Content.module.scss'

import { TABS } from '../../constants'

type TContent = { id: number }

export default function Content({ id }: TContent) {
  const { title, text, link, search: showSearch, buttonText } = TABS[id].content

  const handleClick = () => {}

  return (
    <div className={classes.root}>
      <b className={classes.title}>{title}</b>
      <div className={classes.description}>
        <p className={classes.text}>{text}</p>
        {link && (
          <a href={link} className={classes.link}>
            Read more
            <IconArrow />
          </a>
        )}
      </div>
      {showSearch && (
        <div className={classes.search}>
          <Search />
        </div>
      )}
      {link && !showSearch && (
        <div className={classes.search}>
          <Button
            text={buttonText || ''}
            Icon={Icon}
            theme="contrast"
            onClick={handleClick}
            href={link}
            type="link"
          />
        </div>
      )}
    </div>
  )
}
